import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import useAuth from "../hooks/useAuth";
import { Toaster } from "react-hot-toast";
import PodcastIcon from "../UI/PodcastIcon";
import RegisterModal from "../UI/RegisterModal";
import { axiosPublic } from "../common/axiosPublic";
import { Helmet } from "react-helmet";
import { AuthLayout } from "./Landing/AuthLayout";
import { Link, useParams } from "react-router-dom";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/solid";
import SEO from "./SEO";

const Register = () => {
  const [apiError, setApiError] = useState();
  const [RegistrationOk, setRegistrationOk] = useState();
  const [show, setShow] = useState(false);
  const [userEmail, setUserEmail] = useState();
  const { setAuth } = useAuth();
  const [userAgreedTerms, setUserAgreedTerms] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  let navigate = useNavigate();

  // const HandleOnClose = () => {
  //   setShow(false);
  //   navigate("/login", { replace: true });
  // };

  // const HandleOnReSend = async () => {
  //   const response = await axiosPublic
  //     .post("/auth/resend-verification-token", { email: userEmail })
  //     .then((response) => {
  //       // Handle successful request
  //       toast.success("Activation email was sent again");
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       console.log(userEmail);
  //       toast.error("Error communicating with the server");
  //     });
  // };

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm();
  // when Form is submitted
  const onFormSubmit = async (data) => {
    setIsSubmitted(true);

    if (!userAgreedTerms) {
      return;
    }

    const response = await axiosPublic
      .post("/auth/register", data)
      .then((response) => {
        // Handle successful request
        setRegistrationOk(true);
        toast.success("Registration successful");
        // Show the modal box
        setShow(true);
        // set the user
        setUserEmail(data.email);
        navigate("/registration-success", { replace: true });
      })
      .catch((error) => {
        // if 422 unprocessable entity
        if (error.response.status === 422) {
          toast.error("Password must be at least 8 characters long and contain at least one number and one letter");
        } else if (error.response.status === 409) {
          toast.error("Account with this email already exists. Please login or reset your password");
        } else {
          setApiError("Error communicating with the server");
        }
        console.log(error);
      })
      .finally(() => {
        // always executed
      });
  };

  const onErrors = (errors) => console.error(errors);

  return (
    // show in the middle of the screen to check for the activation email
    <>
      <SEO title="PodScribe.IO | Register" />
      <AuthLayout>
        <Toaster position="top-right" />
        <div className="flex flex-col">
          <Link to="/" aria-label="Home">
            <div className="flex items-center justify-center">
              <PodcastIcon className="h-10 w-10 fill-primary-600" />
              <h1 className="ml-2 text-4xl font-bold text-primary-900 bg-gradient-to-r from-primary-600 to-primary-900 bg-clip-text text-transparent">
                PodScribe.IO
              </h1>
            </div>
          </Link>

          <div className="mt-20">
            <h2 className="text-lg font-semibold text-secondary-700">Join Today!</h2>
            <p className="mt-2 text-sm text-primary-700">
              Sign up now and turn your favorite podcasts into a knowledge database, right at your fingertips.
            </p>
            <p className="mt-2 text-sm text-primary-700">
              Already has an account?{" "}
              <Link to="/login" className="font-medium text-blue-600 hover:underline">
                Sign in
              </Link>{" "}
              now.
            </p>
          </div>
          <form onSubmit={handleSubmit(onFormSubmit, onErrors)} className="mt-10 grid grid-cols-1 gap-y-8">
            <div>
              <label htmlFor="username" className="block text-sm font-medium text-primary-700">
                Username
              </label>
              <div className="mt-1">
                <input
                  id="username"
                  name="username"
                  type="username"
                  autoComplete="username"
                  required
                  className="appearance-none block w-full px-3 py-2 border border-primary-300 rounded-md shadow-sm placeholder-primary-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                  {...register("name", {
                    required: "The username is required",
                  })}
                />
                <span className="text-support-red-500">{errors?.name && errors.name.message} </span>
              </div>
            </div>
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-primary-700">
                Email address
              </label>
              <div className="mt-1">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="appearance-none block w-full px-3 py-2 border border-primary-300 rounded-md shadow-sm placeholder-primary-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                  {...register("email", { required: "The email is required" })}
                />
                <span className="text-support-red-500">{errors?.email && errors.email.message}</span>
              </div>
            </div>

            <div>
              <label htmlFor="password" className="block text-sm font-medium text-primary-700">
                Password
              </label>
              <div className="mt-1">
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  className="appearance-none block w-full px-3 py-2 border border-primary-300 rounded-md shadow-sm placeholder-primary-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                  {...register("password", {
                    required: "The password is required",
                  })}
                />
                <span className="text-support-red-500">{errors?.password && errors.password.message} </span>
              </div>
            </div>

            <div>
              <label htmlFor="passwordConfirm" className="block text-sm font-medium text-primary-700">
                Repeat Password
              </label>
              <div className="mt-1">
                <input
                  id="passwordConfirm"
                  name="passwordConfirm"
                  type="password"
                  autoComplete="passwordConfirm"
                  required
                  className="appearance-none block w-full px-3 py-2 border border-primary-300 rounded-md shadow-sm placeholder-primary-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                  {...register("passwordConfirm", {
                    required: "The password is required",
                    validate: (val) => {
                      if (watch("password") != val) {
                        return "Your passwords do no match!";
                      }
                    },
                  })}
                />
                <span className="text-support-red-500">
                  {errors?.passwordConfirm && errors.passwordConfirm.message}
                </span>
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">
                Sign up
              </button>
              {isSubmitted && !userAgreedTerms && <p className=" text-red-600">Please read and agree the Terms</p>}
              <div className="mt-3 relative flex items-start">
                <div className="flex h-6 items-center">
                  <input
                    id="agree"
                    aria-describedby="agree-description"
                    name="agree"
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    onChange={(e) => setUserAgreedTerms(e.target.checked)}
                  />
                </div>
                <div className="ml-3 text-sm leading-6">
                  <label htmlFor="agree" className=" font-bold text-black-900">
                    I agree{" "}
                  </label>{" "}
                  <span id="agree-description" className=" text-neutral-900">
                    <span className="sr-only">Terms and Services</span>
                    to the{" "}
                    <Link to="/terms" className="font-medium text-primary-600 hover:underline">
                      Terms and Services
                    </Link>{" "}
                    and{" "}
                    <Link to="/privacy-policy" className="font-medium text-primary-600 hover:underline">
                      Privacy Policy{" "}
                    </Link>
                    of PodScribe.IO
                  </span>
                </div>
              </div>
            </div>
          </form>
        </div>
      </AuthLayout>
    </>
  );
};

const VerifyEmail = (props) => {
  let { ActivationCode } = useParams();
  const [activationState, setActivationState] = useState('loading');

  useEffect(() => {
    const verifyEmail = async () => {
      try {
        await axiosPublic.post(`/auth/verifyemail/${ActivationCode}`);
        setActivationState('success');
      } catch (error) {
        console.log(error);
        setActivationState('error');
      }
    };
    verifyEmail();
  }, [ActivationCode]);

  return (
    <>
      <SEO title="PodScribe.IO | Account Activation" />
      <main className="grid min-h-full place-items-center bg-white py-24 px-6 sm:py-32 lg:px-8">
        <div className="text-center">
          {activationState === 'loading' && (
            <>
              <svg className="animate-spin h-24 w-24 text-primary-600 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              <h1 className="mt-4 text-3xl font-bold tracking-tight text-neutral-900 sm:text-5xl">
                Verifying Your Account
              </h1>
              <p className="mt-6 text-base leading-7 text-neutral-600">
                Please wait while we activate your account...
              </p>
            </>
          )}
          {activationState === 'success' && (
            <>
              <CheckCircleIcon className="h-24 w-24 text-primary-600 mx-auto" />
              <h1 className="mt-4 text-3xl font-bold tracking-tight text-neutral-900 sm:text-5xl">
                Welcome to PodScribe.IO!
              </h1>
              <p className="mt-6 text-base leading-7 text-support-green-600">Your account has been activated.</p>
              <p className="mt-6 text-base leading-7 text-neutral-600">
                Please head to the login page to start using PodScribe.IO.
              </p>
            </>
          )}
          {activationState === 'error' && (
            <>
              <XCircleIcon className="h-24 w-24 text-primary-600 mx-auto" />
              <h1 className="mt-4 text-3xl font-bold tracking-tight text-neutral-900 sm:text-5xl">
                Activation Failed
              </h1>
              <p className="mt-6 text-base leading-7 text-neutral-600">
                Your account could not be activated or it is already activated. If you have any questions, please contact us!
              </p>
            </>
          )}

          <div className="mt-10 flex items-center justify-center gap-x-6">
            <Link
              to="/login"
              className="rounded-md bg-primary-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600">
              Go to Login
            </Link>
            <a href="mailto:info@podscribe.io" className="text-sm font-semibold text-neutral-900">
              Contact support <span aria-hidden="true">&rarr;</span>
            </a>
          </div>
        </div>
      </main>
    </>
  );
};
export { VerifyEmail };
export default Register;
