import { React, useState, useEffect } from "react";
import {
  HeartIcon,
  ChevronRightIcon,
  ExternalLinkIcon,
  FolderAddIcon,
  FolderRemoveIcon,
  LightningBoltIcon,
  DownloadIcon,
} from "@heroicons/react/outline";
import { PlayIcon, PauseIcon, LightBulbIcon } from "@heroicons/react/solid";
import { Link } from "react-router-dom";
import ReactPlayer from "react-player";
import Img from "./Img";
import { Transition } from "@headlessui/react";

function Player({ url }) {
  const [playing, setPlaying] = useState(false);

  const handlePlayPause = () => {
    setPlaying(!playing);
  };

  return (
    <div className="flex items-center justify-center">
      <button onClick={handlePlayPause}>
        {playing ? (
          <PauseIcon className="h-6 w-6 text-primary-500" aria-hidden="true" />
        ) : (
          <PlayIcon className="h-6 w-6 text-primary-500" aria-hidden="true" />
        )}
      </button>
      <ReactPlayer
        url={url}
        playing={playing}
        width="0"
        height="0"
        config={{
          file: {
            forceAudio: true,
            preload: false,
          },
        }}
      />
    </div>
  );
}

export default function PodcastEpisodeCard(props) {
  const { episode, onCloseModal } = props;
  const [isLoading, setIsLoading] = useState(false);

  const handleAddRemove = async (feedId, episodeId) => {
    setIsLoading(true);
    try {
      await props.handleAddRemove(feedId, episodeId, !episode.is_added_to_library);
    } catch (error) {
      console.error("Error adding/removing episode:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleViewInLibrary = () => {
    if (onCloseModal) {
      onCloseModal();
    }
  };

  useEffect(() => {
    console.log("Episode in the PodcastEpisodeCard: ", episode);
  }, [episode]);

  return (
    <div className="bg-white shadow-md rounded-lg overflow-hidden transition-all duration-300 hover:shadow-lg mt-6 mb-8 sm:mb-6 flex flex-col items-center sm:flex-row sm:items-start">
      <div className="p-4 relative w-full flex flex-col items-center sm:items-start">
        <div className="flex flex-col items-center sm:flex-row sm:items-start w-full">
          <div className="flex-shrink-0 mb-4 sm:mb-0 sm:mr-4 flex justify-center">
            <Img
              src={episode.feedImage}
              alt={episode.title}
              className="h-24 w-24 sm:h-20 sm:w-20 rounded-md object-cover shadow-sm"
            />
          </div>
          <div className="flex-grow text-center sm:text-left">
            <div className="flex flex-col sm:flex-row justify-between items-start mb-2 sm:mb-0">
              <Link to={episode.link} target="_blank" className="group block">
                <h4 className="text-lg font-bold text-primary-600 group-hover:text-secondary-600 transition-colors duration-200 pr-0 sm:pr-0">
                  {episode.title.length > 50 ? episode.title.substring(0, 50) + "..." : episode.title}
                </h4>
              </Link>
            </div>
            <div className="flex flex-wrap items-center justify-center gap-3 mt-2 sm:justify-start">
              <span className="text-sm text-neutral-500">
                {new Date(episode.datePublished * 1000).toLocaleDateString("en-US")}
              </span>
              <div className="flex items-center space-x-3">
                <Link to={episode.enclosureUrl} target="_blank" className="text-primary-600 hover:text-secondary-600">
                  <DownloadIcon className="h-5 w-5" aria-hidden="true" />
                </Link>
                <Player url={episode.enclosureUrl} />
              </div>
              {episode.is_transcribed && (
                <span className="inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium bg-secondary-100 text-secondary-800">
                  <LightningBoltIcon className="h-3 w-3 mr-1" aria-hidden="true" />
                  Podscribed
                </span>
              )}
            </div>
          </div>
        </div>
        <p className="mt-3 text-sm text-neutral-600 text-center sm:text-left break-words">
          {episode.description.length > 300 ? `${episode.description.substring(0, 297)}...` : episode.description}
        </p>

        {episode.is_added_to_library ? (
          <Link
            to={`/library/${episode.id}`}
            className="mt-4 self-center sm:absolute sm:top-4 sm:right-4 transition-all duration-200 px-3 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 active:scale-95 flex items-center bg-green-100"
            onClick={handleViewInLibrary}>
            <svg className="h-5 w-5 text-green-600 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
            </svg>
            <span className="text-sm font-medium">View in Library</span>
          </Link>
        ) : (
          <button
            onClick={() => handleAddRemove(episode.feedId, episode.id)}
            className={`mt-4 self-center sm:absolute sm:top-4 sm:right-4 transition-transform duration-200 px-4 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 active:scale-95 flex items-center justify-center ${
              isLoading ? "bg-gray-200 cursor-not-allowed" : "bg-primary-600 hover:bg-primary-700"
            }`}
            aria-label="Add to library"
            disabled={isLoading}>
            {isLoading ? (
              <svg
                className="animate-spin h-5 w-5 text-white mr-2"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
            ) : (
              <FolderAddIcon className="h-5 w-5 text-white mr-2" aria-hidden="true" />
            )}
            <span className="text-sm font-medium text-white">{isLoading ? "Adding..." : "Add"}</span>
          </button>
        )}
      </div>
    </div>
  );
}
