import react, { useEffect } from "react";
import { useState, useRef, useReducer, useContext } from "react";
import classNames from "classnames";
import Img from "../../UI/Img";
import { remove_html_tags } from "../../common/utils";
import { format } from "date-fns";
import InboxSidebar from "./MobileInbox";
import { PlusIcon } from "@heroicons/react/outline";
import { SortAscendingIcon, SortDescendingIcon, ViewListIcon, ViewGridIcon, GiftIcon } from "@heroicons/react/solid";
import { PlusIcon as PlusIconOutline } from "@heroicons/react/outline";
import AddInboxContentModal from "./add_inbox/AddInboxContentModal";
import useUIState from "../../hooks/useUIState";
import SearchInput from "../../UI/SearchComponent";
import { useDebounceValue } from "usehooks-ts";
import useAuth from "../../hooks/useAuth";
import { Link } from "react-router-dom";

function Highlight({ text = "", highlight = "", className = "" }) {
  if (!highlight.trim()) {
    return <span className={className}>{text}</span>;
  }
  const regex = new RegExp(`(${highlight})`, "gi");
  const parts = text.split(regex);
  return (
    <span className={className}>
      {parts.filter((part) => part).map((part, i) => (regex.test(part) ? <mark key={i}>{part}</mark> : part))}
    </span>
  );
}

const InitialInboxState = {
  episode_list: [],
  filteredEpisodeList: [],
  selectedEpisode: null,
};

function sortEpisodes(episode_list, sortKey, sortOrder) {
  if (sortKey === "datePublished") {
    if (sortOrder === "asc") {
      return episode_list.sort((a, b) => a.datePublished - b.datePublished);
    } else {
      return episode_list.sort((a, b) => b.datePublished - a.datePublished);
    }
  } else if (sortKey === "title") {
    if (sortOrder === "asc") {
      return episode_list.sort((a, b) => a.title.localeCompare(b.title));
    } else {
      return episode_list.sort((a, b) => b.title.localeCompare(a.title));
    }
  } else if (sortKey === "podcast") {
    if (sortOrder === "asc") {
      return episode_list.sort((a, b) => a.podcast.localeCompare(b.podcast));
    } else {
      return episode_list.sort((a, b) => b.podcast.localeCompare(a.podcast));
    }
  } else if (sortKey === "podcast_title") {
    if (sortOrder === "asc") {
      return episode_list.sort((a, b) => a.podcast_title.localeCompare(b.podcast_title));
    } else {
      return episode_list.sort((a, b) => b.podcast_title.localeCompare(a.podcast_title));
    }
  } else {
    return episode_list;
  }
}

function groupEpisodes(episode_list, groupKey) {
  if (groupKey === "podcast") {
    return episode_list.sort((a, b) => a.podcast.localeCompare(b.podcast));
  } else if (groupKey === "podcast_title") {
    return episode_list.sort((a, b) => a.podcast_title.localeCompare(b.podcast_title));
  } else {
    return episode_list;
  }
}

function searchEpisodes(episode_list, searchTerm) {
  if (searchTerm === "") {
    return episode_list;
  } else {
    const lowerCaseSearchTerm = searchTerm.toLowerCase();
    return episode_list.filter((episode) => {
      return (
        episode.title.toLowerCase().includes(lowerCaseSearchTerm) ||
        // episode.description.toLowerCase().includes(lowerCaseSearchTerm) ||
        episode.podcast_title.toLowerCase().includes(lowerCaseSearchTerm)
      );
    });
  }
}

function inboxReducer(state, action) {
  switch (action.type) {
    case "INIT":
      return {
        ...state,
        episode_list: action.episode_list,
        filteredEpisodeList: action.episode_list,
        selectedEpisode: action.selectedEpisode,
      };

    case "SELECT_EPISODE":
      return {
        ...state,
        selectedEpisode: action.selectedEpisode,
      };
    case "SORT_EPISODES":
      return {
        ...state,
        filteredEpisodeList: sortEpisodes(state.filteredEpisodeList, action.sortKey, action.sortOrder),
      };

    case "GROUP_EPISODES":
      return {
        ...state,
        filteredEpisodeList: groupEpisodes(state.filteredEpisodeList, action.groupKey),
      };

    case "UNGROUP_EPISODES":
      return {
        ...state,
        filteredEpisodeList: state.episode_list,
      };

    case "SEARCH_EPISODES":
      return {
        ...state,
        filteredEpisodeList: searchEpisodes(state.episode_list, action.searchTerm),
      };

    default:
      return state;
  }
}

export default function Inbox(props) {
  const [inboxState, dispatchInboxState] = useReducer(inboxReducer, InitialInboxState);
  const [isAscending, setIsAscending] = useState(true);
  const [isGrouped, setIsGrouped] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchValue, setDebouncedSearchValue] = useDebounceValue("", 300);
  const [isAddInboxContentModalOpen, setIsAddInboxContentModalOpen] = useState(false);
  const [isSubscribeModalOpen, setIsSubscribeModalOpen] = useState(false);
  const { UIState, setUIState } = useUIState();
  const { auth } = useAuth();

  const handleInboxSidebar = () => {
    setUIState({
      ...UIState,
      inboxSidebarOpen: !UIState.inboxSidebarOpen,
    });
  };

  // init the inbox data
  useEffect(() => {
    dispatchInboxState({
      type: "INIT",
      episode_list: props.episodes,
      selectedEpisode: props.selectedEpisode,
    });
    // default sort by date
    dispatchInboxState({
      type: "SORT_EPISODES",
      sortKey: "datePublished",
      sortOrder: "desc",
    });
  }, [props.episodes, props.selectedEpisode]);

  // handle the click on the episode
  const handleEpisodeClick = (episode) => {
    dispatchInboxState({ type: "SELECT_EPISODE", selectedEpisode: episode });
    // if the sidebar is open, close it
    if (UIState.inboxSidebarOpen) {
      handleInboxSidebar();
    }
    props.SelectEpisodeHandler(episode.id);
  };

  // handle the sort
  const handleSort = () => {
    setIsAscending(!isAscending);
    if (inboxState.filteredEpisodeList.length > 0) {
      if (isAscending) {
        dispatchInboxState({
          type: "SORT_EPISODES",
          sortKey: "datePublished",
          sortOrder: "asc",
        });
      } else {
        dispatchInboxState({
          type: "SORT_EPISODES",
          sortKey: "datePublished",
          sortOrder: "desc",
        });
      }
    }
  };

  // handle the group
  const handleGroup = () => {
    console.log("filteredEpisodeList", inboxState);
    setIsGrouped(!isGrouped);
    if (isGrouped) {
      dispatchInboxState({ type: "UNGROUP_EPISODES" });
      dispatchInboxState({
        type: "SORT_EPISODES",
        sortKey: "datePublished",
        sortOrder: "asc",
      });
    } else {
      dispatchInboxState({ type: "GROUP_EPISODES", groupKey: "podcast_title" });
    }
  };

  // useEffect to set the selected episode class
  useEffect(() => {
    if (props.selectedEpisodeState) {
      dispatchInboxState({
        type: "SELECT_EPISODE",
        selectedEpisode: props.selectedEpisodeState.episode,
      });
    }
  }, [props.selectedEpisodeState]);

  const handleSearch = (e) => {
    setDebouncedSearchValue(e.target.value);
    setSearchTerm(e.target.value);
  };

  const handleOpenAddInboxContentModal = () => {
    setIsAddInboxContentModalOpen(true);
  };

  // handle the search
  useEffect(() => {
    dispatchInboxState({ type: "SEARCH_EPISODES", searchTerm: debouncedSearchValue });
  }, [debouncedSearchValue]);

  return (
    <>
      <InboxSidebar isEmpty={props.isEmpty}>
        <div className="flex-shrink-0">
          <div className="border-t border-b border-neutral-200 bg-neutral-50 px-6 py-2 text-sm font-medium text-neutral-500 flex justify-between items-center">
            <SearchInput
              value={searchTerm}
              onChange={(e) => {
                handleSearch(e);
              }}
              className=""
            />
            <div>
              <button onClick={handleSort} className="mx-2" title={isAscending ? "Sort Descending" : "Sort Ascending"}>
                {isAscending ? (
                  <SortAscendingIcon className="h-5 w-5 text-neutral-500" />
                ) : (
                  <SortDescendingIcon className="h-5 w-5 text-neutral-500" />
                )}
              </button>
              <button onClick={handleGroup} className="mx-2" title={isGrouped ? "Ungroup" : "Group"}>
                {isGrouped ? (
                  <ViewGridIcon className="h-5 w-5 text-neutral-500" />
                ) : (
                  <ViewListIcon className="h-5 w-5 text-neutral-500" />
                )}
              </button>
            </div>
          </div>
        </div>
        <nav aria-label="episode list" className="flex-1 overflow-y-auto p-1 mb-1">
          <ul role="list" className="border-b border-neutral-200 divide-y divide-neutral-200">
            {inboxState.filteredEpisodeList.map((episode) => (
              <li
                key={episode.id}
                onClick={() => handleEpisodeClick(episode)}
                className={classNames(
                  episode.id === inboxState.selectedEpisode?.id
                    ? "relative bg-slate-100 py-5 px-3 hover:bg-neutral-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-support-yellow-600"
                    : "relative bg-white py-5 px-3 hover:bg-neutral-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-support-yellow-600"
                )}>
                <div className="flex justify-between space-x-3">
                  <div className="min-w-0 flex-1">
                    <div className="flex flex-row space-x-2">
                      <Img
                        src={episode.feedImage}
                        alt=""
                        className="w-10 h-10 rounded-full object-cover text-grey-600"
                      />

                      <div className="flex flex-col">
                        <span className="absolute inset-0" aria-hidden="true" />
                        {searchTerm ? (
                          <p className="">
                            <Highlight
                              text={episode.title}
                              highlight={searchTerm}
                              className="text-sm font-medium text-primary-700 w-40"
                            />
                          </p>
                        ) : (
                          <p className="text-sm font-medium text-primary-700 w-40 truncate">{episode.title}</p>
                        )}
                        {searchTerm ? (
                          <p className="">
                            <Highlight
                              text={episode.podcast_title}
                              highlight={searchTerm}
                              className="text-sm font-medium text-secondary-700 w-56"
                            />
                          </p>
                        ) : (
                          <p className="text-sm font-medium text-secondary-700 w-56 truncate">
                            {episode.podcast_title}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <time
                    dateTime={new Date(episode.datePublished * 1000).toISOString()}
                    className="flex-shrink-0 whitespace-nowrap text-sm text-neutral-500">
                    {format(new Date(episode.datePublished * 1000), "MM/dd/yyyy")}
                  </time>
                </div>
                <div className="flex mt-1 space-x-2">
                  <div className="">
                    {/* {searchTerm ? (
                        <Highlight
                          text={remove_html_tags(episode.description)}
                          highlight={searchTerm}
                          className="text-sm text-neutral-600 line-clamp-3"
                        />
                      ) : (
                        <p className="text-sm text-neutral-600 line-clamp-3">{remove_html_tags(episode.description)}</p>
                      )} */}
                    <p className="text-xs text-neutral-600 line-clamp-3 w-64">
                      {remove_html_tags(episode.description)}
                    </p>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </nav>
      </InboxSidebar>
      <aside className="hidden lg:block lg:flex-shrink-0 lg:order-first">
        <div className="h-screen relative flex flex-col w-96 border-r border-neutral-200 bg-white overflow-y-auto">
          <div className="flex-shrink-0">
            <div className="relative h-16 bg-white px-6 flex flex-col justify-center">
              <div className="flex items-baseline justify-between">
                <div className="flex items-baseline space-x-3">
                  <h2 className="text-lg font-medium text-neutral-900">Inbox</h2>
                  <p className="text-sm font-medium text-neutral-500">{inboxState.episode_list.length} episodes</p>
                </div>
                <button
                  onClick={handleOpenAddInboxContentModal}
                  className="inline-flex items-center justify-center p-2 border border-transparent rounded-md shadow-sm text-white bg-secondary-500 hover:bg-secondary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary-400"
                  title="Add to Inbox">
                  <PlusIcon className="h-5 w-5" aria-hidden="true" />
                  <span className="sr-only">Add to Inbox</span>
                </button>
              </div>
            </div>
            <div className="border-t border-b border-neutral-200 bg-neutral-50 px-6 py-2 text-sm font-medium text-neutral-500 flex justify-between items-center">
              <SearchInput
                value={searchTerm}
                onChange={(e) => {
                  handleSearch(e);
                }}
                className=" w-52"
              />
              <div>
                <button
                  onClick={handleSort}
                  className="mx-2"
                  title={isAscending ? "Sort Descending" : "Sort Ascending"}>
                  {isAscending ? (
                    <SortAscendingIcon className="h-5 w-5 text-neutral-500" />
                  ) : (
                    <SortDescendingIcon className="h-5 w-5 text-neutral-500" />
                  )}
                </button>
                <button onClick={handleGroup} className="mx-2" title={isGrouped ? "Ungroup" : "Group"}>
                  {isGrouped ? (
                    <ViewGridIcon className="h-5 w-5 text-neutral-500" />
                  ) : (
                    <ViewListIcon className="h-5 w-5 text-neutral-500" />
                  )}
                </button>
              </div>
            </div>
          </div>
          <nav aria-label="episode list" className="flex-1 overflow-y-auto p-1 mb-1">
            <ul role="list" className="border-b border-neutral-200 divide-y divide-neutral-200">
              {props.isEmpty ? (
                <li className="py-4 px-4 text-center">
                  <p className="text-gray-500 mb-4">Your inbox is empty.</p>
                  <button
                    onClick={handleOpenAddInboxContentModal}
                    className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-secondary-600 hover:bg-secondary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary-500">
                    <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                    Add Content
                  </button>
                </li>
              ) : (
                inboxState.filteredEpisodeList.map((episode) => (
                  <li
                    key={episode.id}
                    onClick={() => handleEpisodeClick(episode)}
                    className={classNames(
                      episode.id === inboxState.selectedEpisode?.id
                        ? "relative bg-slate-100 py-5 px-3 hover:bg-neutral-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-support-yellow-600"
                        : "relative bg-white py-5 px-3 hover:bg-neutral-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-support-yellow-600"
                    )}>
                    <div className="flex justify-between space-x-3">
                      <div className="min-w-0 flex-1">
                        <div className="flex flex-row space-x-2">
                          <Img
                            src={episode.feedImage}
                            alt=""
                            className="w-10 h-10 rounded-full object-cover text-grey-600"
                          />

                          <div className="flex flex-col">
                            <span className="absolute inset-0" aria-hidden="true" />
                            {searchTerm ? (
                              <p className="">
                                <Highlight
                                  text={episode.title}
                                  highlight={searchTerm}
                                  className="text-sm font-medium text-primary-700"
                                />
                              </p>
                            ) : (
                              <p className="text-sm font-medium text-primary-700 w-48 truncate">{episode.title}</p>
                            )}
                            {searchTerm ? (
                              <p className="">
                                <Highlight
                                  text={episode.podcast_title}
                                  highlight={searchTerm}
                                  className="text-sm font-medium text-secondary-700"
                                />
                              </p>
                            ) : (
                              <p className="text-sm font-medium text-secondary-700 w-64 truncate">
                                {episode.podcast_title}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      <time
                        dateTime={new Date(episode.datePublished * 1000).toISOString()}
                        className="flex-shrink-0 whitespace-nowrap text-sm text-neutral-500">
                        {format(new Date(episode.datePublished * 1000), "MM/dd/yyyy")}
                      </time>
                    </div>
                    <div className="flex mt-1">
                      <div className="">
                        {/* {searchTerm ? (
                        <Highlight
                          text={remove_html_tags(episode.description)}
                          highlight={searchTerm}
                          className="text-sm text-neutral-600 line-clamp-3"
                        />
                      ) : (
                        <p className="text-sm text-neutral-600 line-clamp-3">{remove_html_tags(episode.description)}</p>
                      )} */}
                        <p className="text-sm text-neutral-600 line-clamp-3 max-w-xs">
                          {remove_html_tags(episode.description)}
                        </p>
                      </div>
                    </div>
                  </li>
                ))
              )}
            </ul>
          </nav>
        </div>
      </aside>
      <AddInboxContentModal show={isAddInboxContentModalOpen} onClose={() => setIsAddInboxContentModalOpen(false)} />
    </>
  );
}
