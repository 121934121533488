import { AuthLayout } from "./Landing/AuthLayout";
import { Button } from "./Landing/Button";
import { TextField } from "./Landing/Fields";
import classNames from "classnames";
import PodcastIcon from "../UI/PodcastIcon";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useEffect } from "react";
import useAuth from "../hooks/useAuth";
import { Toaster } from "react-hot-toast";
import { axiosPrivate } from "../common/axiosPrivate";
import { axiosPublic } from "../common/axiosPublic";
import toast from "react-hot-toast";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import SEO from "./SEO";

export default function Login() {
  const [apiError, setApiError] = useState();

  const { setAuth } = useAuth();

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const getUserData = async (token) => {
    try {
      const response = await axiosPrivate.get("/users/me");
      const user = response.data;
      localStorage.setItem("user", JSON.stringify(user));
      setAuth({
        token: token,
        user: user,
        loading: false,
      });
      navigate("/library", { replace: true });
    } catch (error) {
      console.log(error);
      toast.error("Error communicating with the server");
    }
  };

  const onFormSubmit = async (data) => {
    const response = await axiosPublic
      .post("/auth/login", data)
      .then((response) => {
        // Handle successful request
        console.log(response.data);
        if (response.status === 200) {
          const session = response.data;
          localStorage.setItem("session", JSON.stringify(session));
          setAuth({
            token: session.access_token,
            session: session,
            loading: false,
          });
          getUserData(session.access_token);
        }
        return response;
      })
      .catch((error) => {
        // if the login is not successful provide an error message with toast
        if (error.response.status === 400 && error.response.data.detail == "Incorrect Email or Password") {
          toast.error("Incorrect Email or Password");
          setApiError(error);
          setAuth({
            token: null,
            session: null,
            loading: false,
          });
        }
        if (error.response.status === 401 && error.response.data.detail == "Please verify your email address") {
          toast.error("Please verify your email address");
          setApiError(error);
          setAuth({
            token: null,
            loading: false,
          });
        }
        return error.response;
      })
      .finally(() => {
        // always executed
      });
  };
  const onErrors = (errors) => console.error(errors);
  return (
    <>
      <SEO title="PodScribe.IO | Login" />
      <AuthLayout>
        <Toaster position="top-right" />
        <div className="flex flex-col">
          <Link to="/" aria-label="Home">
            <div className="flex items-center justify-center">
              <PodcastIcon className="h-10 w-10 fill-primary-600" />
              <h1 className="ml-2 text-4xl font-bold text-primary-900 bg-gradient-to-r from-primary-600 to-primary-900 bg-clip-text text-transparent">
                PodScribe.IO
              </h1>
            </div>
          </Link>
          <div className="mt-20">
            <h2 className="text-lg font-semibold text-secondary-800">Sign in to your account</h2>
            <p className="mt-2 text-sm text-primary-700">
              Don’t have an account?{" "}
              <Link to="/register" className="font-medium text-blue-600 hover:underline">
                Sign up
              </Link>{" "}
              for a free trial.
            </p>
          </div>

          <form onSubmit={handleSubmit(onFormSubmit, onErrors)} className="mt-10 grid grid-cols-1 gap-y-8">
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-primary-700">
                Email address
              </label>
              <div className="mt-1">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="appearance-none block w-full px-3 py-2 border border-primary-300 rounded-md shadow-sm placeholder-primary-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                  {...register("email", { required: "The email is required" })}
                />
                <span className="text-support-red-500">{errors?.email && errors.email.message}</span>
              </div>
            </div>
            <div>
              <label htmlFor="password" className="mb-3 block text-sm font-medium text-primary-700">
                Password
              </label>
              <div className="mt-1">
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  className="appearance-none block w-full px-3 py-2 border border-primary-300 rounded-md shadow-sm placeholder-primary-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                  {...register("password", {
                    required: "The password is required",
                  })}
                />
                <span className="text-support-red-500">{errors?.password && errors.password.message} </span>
              </div>
            </div>
            <div>
              <button
                type="submit"
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">
                Sign in
              </button>
            </div>
          </form>
        </div>
      </AuthLayout>
    </>
  );
}
