import react, { useEffect } from "react";
import { Fragment, useState, useRef, useMemo } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import moment, { duration } from "moment";
import InboxContent from "./InboxContent";
import ReactPlayer from "react-player";
import "react-h5-audio-player/lib/styles.css";
import "./player.css";
import { Waveform } from "../../UI/WaveForm";
import parse from "html-react-parser";
import { DocumentTextIcon, TrashIcon, ExternalLinkIcon } from "@heroicons/react/outline";
import { ShareIcon } from "@heroicons/react/solid";
import { axiosPrivate } from "../../common/axiosPrivate";
import { toast } from "react-hot-toast";
import useAuth from "../../hooks/useAuth";
import useUIState from "../../hooks/useUIState";
import { useCopyToClipboard } from "usehooks-ts";
import ActionModalInfo from "../../UI/ActionModalInfo";
import ActionModalPleaseSubscribeToUse from "../../UI/ActionModalPleaseSubscribeToUse";
import { Banner } from "../../UI/Banner";
import { link } from "react-router-dom";
import { ExclamationIcon } from "@heroicons/react/solid";
import {
  ArchiveIcon as ArchiveIconSolid,
  ChevronDownIcon,
  ChevronUpIcon,
  DotsVerticalIcon,
  FolderDownloadIcon,
  PencilIcon,
  ReplyIcon,
  SearchIcon,
  UserAddIcon,
  LightningBoltIcon,
  ChatAltIcon,
  MenuAlt1Icon,
  GiftIcon,
  SearchCircleIcon,
  ArrowUpIcon,
  PlusIcon,
  ThumbUpIcon,
} from "@heroicons/react/solid";
import { BarLoader, BeatLoader } from "react-spinners";
import Img from "../../UI/Img";
import PodscribeActionModal from "./PodscribeActionModal";
import { remove_html_tags } from "../../common/utils";
import { Link } from "react-router-dom";
import useLibraryData from "../../hooks/useLibraryData";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const EmptyInboxMessage = (props) => {
  const [showEditor, setShowEditor] = useState(true);
  const [showTranscribe, setShowTranscribe] = useState(true);
  const [playAudio, setPlayAudio] = useState(false);
  const [toggleEpisodeDescription, setToggleEpisodeDescription] = useState(true);
  const [TranscribeInProgress, setTranscribeInProgress] = useState(false);
  const [showFullDescription, setShowFullDescription] = useState(false);
  const [showPodscribeModal, setShowPodscribeModal] = useState(false);
  const { auth } = useAuth();
  const [copiedText, copy] = useCopyToClipboard();
  const [shareUrl, setShareUrl] = useState("");
  const [showShareModal, setShowShareModal] = useState(false);
  const [showSubscribeModal, setShowSubscribeModal] = useState(false);
  const { UIState, setUIState } = useUIState();
  const { startEpisodePolling } = useLibraryData();

  const handleInboxSidebar = () => {
    setUIState({
      ...UIState,
      inboxSidebarOpen: !UIState.inboxSidebarOpen,
    });
  };

  return (
    <section
      aria-labelledby="message-heading"
      className="min-w-0 flex-1 lg:h-screen flex flex-col overflow-y-auto lg:order-last">
      {/* Top section */}
      <div className="flex-shrink-0 bg-white border-b border-neutral-200">
        {/* Toolbar*/}

        <div className="h-16 flex flex-col justify-center">
          <div className="px-4 sm:px-6 lg:px-8">
            <div className="py-3 flex justify-between">
              {/* Left buttons */}
              <div className="">
                <span className="relative z-0 inline-flex shadow-sm rounded-md">
                  <button
                    type="button"
                    className="lg:hidden mr-5 relative inline-flex items-center px-2 py-2 rounded-md border border-secondary-300 bg-secondary-100 text-sm font-medium text-secondary-700 hover:bg-secondary-200 focus:z-10 focus:outline-none focus:ring-1 focus:ring-secondary-500 focus:border-secondary-500"
                    onClick={() => handleInboxSidebar()}>
                    <span className="sr-only">Open Mobile sidebar</span>
                    <MenuAlt1Icon className="h-5 w-5" aria-hidden="true" />
                  </button>
                  <div className="inline-flex rounded-md shadow-sm">
                    <button
                      type="button"
                      disabled={true}
                      className="relative inline-flex items-center sm:px-4 px-2 py-1 rounded-l-md border border-neutral-300 bg-neutral-100 text-sm font-medium text-neutral-400 cursor-not-allowed">
                      <LightningBoltIcon className="h-5 w-5" aria-hidden="true" />
                      <span className="hidden sm:block ml-2">Podscribe</span>
                    </button>
                    <Menu as="div" className="relative -ml-px block">
                      <Menu.Button
                        disabled
                        className="relative inline-flex items-center sm:px-4 px-2 py-2 rounded-r-md border border-neutral-300 bg-neutral-100 text-sm font-medium text-neutral-400 cursor-not-allowed">
                        <span className="sr-only">Open options</span>
                        <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                      </Menu.Button>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95">
                        <Menu.Items className="absolute right-0 z-10 -mr-1 mt-2 w-32 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <div className="py-1">
                            <Menu.Item>
                              {({ active }) => (
                                <button
                                  type="button"
                                  title="Share"
                                  disabled={true}
                                  className={classNames(
                                    active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                                    "relative inline-flex items-center px-2 py-1 text-sm font-medium text-neutral-500 w-full"
                                  )}>
                                  <ShareIcon className="h-5 w-5 mr-2 fill-primary-500" aria-hidden="true" />
                                  Share
                                </button>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <button
                                  type="button"
                                  title="Transcribe"
                                  className={classNames(
                                    active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                                    "relative inline-flex items-center px-2 py-1 text-sm font-medium text-neutral-500 w-full"
                                  )}>
                                  <ThumbUpIcon className="h-5 w-5 mr-2 fill-primary-500" aria-hidden="true" />
                                  Like
                                </button>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <button
                                  type="button"
                                  disabled={true}
                                  className={classNames(
                                    active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                                    "relative inline-flex items-center px-2 py-1 text-sm font-medium text-neutral-500 w-full"
                                  )}>
                                  <TrashIcon className="h-5 w-5 mr-2 text-rose-800" aria-hidden="true" />
                                  Remove
                                </button>
                              )}
                            </Menu.Item>
                          </div>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                </span>
              </div>

              {/* Right buttons */}
              <nav aria-label="Pagination">
                <span className="relative z-0 inline-flex shadow-sm rounded-md">
                  <button
                    disabled={true}
                    className="relative inline-flex items-center sm:px-4 px-2 py-2 rounded-l-md border border-neutral-300 bg-neutral-100 text-sm font-medium text-neutral-400 cursor-not-allowed">
                    <span className="sr-only">Previous</span>
                    <SearchIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                  <button
                    disabled={true}
                    className="-ml-px relative inline-flex items-center sm:px-4 px-2 py-2 border border-neutral-300 bg-neutral-100 text-sm font-medium text-neutral-400 cursor-not-allowed">
                    <span className="sr-only">Previous</span>
                    <ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                  <button
                    disabled={true}
                    className="-ml-px relative inline-flex items-center sm:px-4 px-2 py-2 rounded-r-md border border-neutral-300 bg-neutral-100 text-sm font-medium text-neutral-400 cursor-not-allowed">
                    <span className="sr-only">Next</span>
                    <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                </span>
              </nav>
            </div>
          </div>
        </div>
        {/* Message header */}
      </div>
      <div className="flex-1 overflow-auto">
        <div className="flex flex-col items-center justify-center h-full text-center px-4 sm:px-6 lg:px-8">
          <svg
            className="mx-auto h-12 w-12 text-gray-400"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true">
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"
            />
          </svg>
          <h3 className="mt-2 text-sm font-medium text-gray-900">No episode selected</h3>
          <p className="mt-1 text-sm text-gray-500">Get started by selecting an episode from your Inbox.</p>
          <div className="mt-6 lg:hidden">
            <button
              type="button"
              className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-secondary-600 hover:bg-secondary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary-500"
              onClick={() => handleInboxSidebar()}>
              <MenuAlt1Icon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
              Open Inbox
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default EmptyInboxMessage;
