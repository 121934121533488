import React from "react";
import { HeartIcon, ChevronRightIcon } from "@heroicons/react/solid";
import { HeartIcon as HeartIconOutlined, ExternalLinkIcon } from "@heroicons/react/outline";
import { Link } from "react-router-dom";
import Img from "./Img";
import Button from "./Button";

export default function PodcastCard({ feed, onClick }) {
  return (
    <div className="bg-white shadow-md rounded-lg overflow-hidden transition-all duration-300 hover:shadow-lg mt-6 mb-8 sm:mb-6 relative flex flex-col items-center sm:flex-row sm:items-start">
      <div className="p-4 w-full flex flex-col items-center sm:items-start">
        <div className="flex flex-col items-center sm:flex-row sm:items-start w-full">
          <div className="flex-shrink-0 mb-4 sm:mb-0 sm:mr-4 flex justify-center">
            <Img
              src={feed.image}
              alt={feed.title}
              className="h-24 w-24 sm:h-32 sm:w-32 rounded-md object-cover shadow-sm mx-auto sm:mx-0"
            />
          </div>
          <div className="flex-grow text-center sm:text-left">
            <div className="flex flex-col sm:flex-row justify-between items-center sm:items-start w-full">
              <div className="group mb-2 sm:mb-0">
                <h4 className="text-lg font-bold text-primary-600 transition-colors duration-200 flex items-center justify-center sm:justify-start">
                  {feed.title.length > 50 ? feed.title.substring(0, 50) + "..." : feed.title}
                </h4>
              </div>
            </div>
            <h4 className="text-sm font-medium text-neutral-600 text-center sm:text-left">
              by <span className="text-secondary-600">{feed.author}</span>
            </h4>
            <div className="flex flex-wrap mt-2 gap-1 justify-center sm:justify-start w-full">
              {feed.categories &&
                Object.values(feed.categories).map((category, i) => (
                  <span
                    key={i}
                    className="inline-flex items-center rounded-full bg-primary-50 px-2.5 py-0.5 text-xs font-medium text-primary-700 ring-1 ring-inset ring-primary-700/10">
                    {category}
                  </span>
                ))}
            </div>
          </div>
        </div>
        <p className="mt-3 text-sm text-neutral-600 text-center sm:text-left break-words">{feed.description}</p>
        <div className="mt-4 self-center sm:absolute sm:top-4 sm:right-4">
          <Button onClick={() => onClick(feed)} className="py-1 px-2 text-xs sm:text-sm sm:py-2 sm:px-3">
            View Episodes
          </Button>
        </div>
      </div>
    </div>
  );
}
